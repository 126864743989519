import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useSectionsList() {
  // Use toast
  const toast = useToast();

  const refSectionsListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "name" },
    { key: "lessons" },
    { key: "exercises" },
    { key: "actions" },
  ];
  const per_page = ref(10);
  const totalSections = ref(0);
  const current_page = ref(1);
  const per_pageOptions = [10, 20, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  // Add section variables
  const addSectionModal = ref(false)
  const addSectionName = ref("")
  const addTranslation = ref("no")
  const addSectionNameTranslation = ref("")

  // Single section Data
  const sectionData = ref(null);

  // Edit section variables
  const editSectionModal = ref(false);
  const editSectionId = ref("");
  const editSectionName = ref("");
  
  const editTranslation = ref("no")
  const editSectionNameTranslation = ref("");

  const dataMeta = computed(() => {
    const localItemsCount = refSectionsListTable.value
      ? refSectionsListTable.value.localItems.length
      : 0;
    return {
      from:
        per_page.value * (current_page.value - 1) + (localItemsCount ? 1 : 0),
      to: per_page.value * (current_page.value - 1) + localItemsCount,
      of: totalSections.value,
    };
  });

  const refetchData = () => {
    refSectionsListTable.value.refresh();
  };

  watch([current_page, per_page, searchQuery], () => {
    refetchData();
  });

  const fetchSections = (ctx, callback) => {
    store
      .dispatch("app-content/fetchSections", {
        id: router.currentRoute.params.chapter_id,
        q: searchQuery.value,
        per_page: per_page.value,
        page: current_page.value,
      })
      .then((response) => {
        const { data: sections, total } = response.data.data;

        callback(sections);
        totalSections.value = total;
      })
      .catch((error) => {
        console.log(error);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching sections list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const storeSection = (ctx, callback) => {
    const formData = new FormData();
    formData.append('chapter_id', router.currentRoute.params.chapter_id)
    formData.append("name", addSectionName.value);
    formData.append("translation[name]", addSectionNameTranslation.value)

    store
      .dispatch("app-content/addSection", formData)
      .then(() => {
        refetchData();
        addSectionModal.value = false;
        addSectionName.value = "";
        addTranslation.value = false;
        addSectionNameTranslation.value = ""
        toast({
          component: ToastificationContent,
          props: {
            title: "Section Added.",
            icon: "CheckIcon",
            variant: "success",
          },
        });
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "An error occurred while adding the section.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const updateSection = () => {
    const formData = new FormData();
    formData.append("name", editSectionName.value);
    formData.append("translation[name]", editSectionNameTranslation.value)

    store
      .dispatch("app-content/updateSection", {
        id: editSectionId.value,
        data: formData,
      })
      .then((response) => {
        sectionData.value = response.data.data;
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: "Section updated.",
            icon: "CheckIcon",
            variant: "success",
          },
        });
        editSectionModal.value = false;
        editSectionId.value = "";
        editSectionName.value = "";
        editSectionNameTranslation.value = "";
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "An error occurred while updating the section details.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchSections,
    storeSection,
    updateSection,
    tableColumns,
    per_page,
    current_page,
    totalSections,
    dataMeta,
    per_pageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refSectionsListTable,
    refetchData,

    addSectionModal,
    addSectionName,
    addTranslation,
    addSectionNameTranslation,

    sectionData,

    editSectionModal,
    editSectionId,
    editSectionName,
    editTranslation,
    editSectionNameTranslation,
  };
}
