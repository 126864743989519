<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="per_page"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="per_pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
            <b-button
              class="ml-1"
              variant="primary"
              @click="addSectionModal = true"
            >
              Add Section
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- Add Section Modal -->
      <b-modal
        v-model="addSectionModal"
        hide-footer
        centered
        size="lg"
        title="Add Section"
      >
        <b-form-group label="Enter the Chapter Name" label-for="enterName">
          <b-form-input
            id="enterName"
            placeholder="Enter the Chapter Name"
            v-model="addSectionName"
          />
        </b-form-group>

        <div class="demo-inline-spacing mb-1">
          <span>Add Translation? </span>
          <b-form-radio
            v-model="addTranslation"
            name="some-radio9"
            value="yes"
            class="custom-control-primary"
          >
            Yes
          </b-form-radio>
          <b-form-radio
            v-model="addTranslation"
            name="some-radio9"
            value="no"
            class="custom-control-primary"
          >
            No
          </b-form-radio>
        </div>

        <div v-if="addTranslation === 'yes'">
          <b-form-group label="Enter the Section Name Translation" label-for="enterNameTranslation">
            <b-form-input
              id="enterNameTranslation"
              placeholder="Enter the Section Name Translation"
              v-model="addSectionNameTranslation"
            />
          </b-form-group>
        </div>

        <b-button variant="outline-primary" @click="storeSection()">
          Submit
        </b-button>
      </b-modal>

      <!-- Edit Section Modal -->
      <b-modal
        v-model="editSectionModal"
        hide-footer
        centered
        size="lg"
        title="Edit Section"
      >
        <b-form-group label="Enter the Section Name" label-for="enterName">
          <b-form-input
            id="enterName"
            placeholder="Enter the Section Name"
            v-model="editSectionName"
          />
        </b-form-group>

        <div class="demo-inline-spacing mb-1">
          <span>Add/Edit Translation? </span>
          <b-form-radio
            v-model="editTranslation"
            name="some-radio9"
            value="yes"
            class="custom-control-primary"
          >
            Yes
          </b-form-radio>
          <b-form-radio
            v-model="editTranslation"
            name="some-radio9"
            value="no"
            class="custom-control-primary"
          >
            No
          </b-form-radio>
        </div>

        <div v-if="editTranslation === 'yes'">
          <b-form-group label="Enter/Edit the Section Name Translation" label-for="editNameTranslation">
            <b-form-input
              id="editNameTranslation"
              placeholder="Enter/Edit the Section Name Translation"
              v-model="editSectionNameTranslation"
            />
          </b-form-group>
        </div>

        <b-button variant="outline-primary" @click="updateSection">
          Submit
        </b-button>
      </b-modal>
      
      <b-table
        ref="refSectionsListTable"
        class="position-relative"
        :items="fetchSections"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Lessons -->
        <template #cell(lessons)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.lessons.length }}</span>
          </div>
        </template>

        <!-- Column: Exercises -->
        <template #cell(exercises)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.exercises.length }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            class="mr-1"
            variant="outline-info"
            size="sm"
            :to="{ name: 'apps-lessons-list', params: { chapter_id: data.item.chapter.id, section_id: data.item.id } }"
          >
            View Lessons
          </b-button>
          <b-button
            variant="outline-danger"
            size="sm"
            @click="editSectionDetails(data.item)"
          >
            Edit
          </b-button>
        </template>
      </b-table>
      
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="current_page"
              :total-rows="totalSections"
              :per-page="per_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormSelect,
  BModal,
  BFormCheckbox,
  BFormTextarea,
  BFormFile,
  BCardText,
  BFormRadio,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useSectionsList from "./useSectionsList";
import contentStore from "../contentStore";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormSelect,
    BModal,
    BFormCheckbox,
    BFormTextarea,
    BFormFile,
    BCardText,
    BFormRadio,

    vSelect,
  },
  setup() {
    const CONTENT_APP_STORE_MODULE_NAME = "app-content";

    // Register module
    if (!store.hasModule(CONTENT_APP_STORE_MODULE_NAME))
      store.registerModule(CONTENT_APP_STORE_MODULE_NAME, contentStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONTENT_APP_STORE_MODULE_NAME))
        store.unregisterModule(CONTENT_APP_STORE_MODULE_NAME);
    });

    const editSectionDetails = (item) => {
      sectionData.value = item
      editSectionId.value = sectionData.value.id
      editSectionName.value = sectionData.value.name
      editTranslation.value = 'no'
      if (sectionData.value.translation != null) {
        editSectionNameTranslation.value = sectionData.value.translation.name
        editTranslation.value = 'yes'
      }

      editSectionModal.value = true
    }

    const isAddNewSectionSidebarActive = ref(false);

    const {
      fetchSections,
      storeSection,
      updateSection,
      tableColumns,
      per_page,
      current_page,
      totalSections,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSectionsListTable,
      refetchData,

      addSectionModal,
      addSectionName,
      addTranslation,
      addSectionNameTranslation,

      sectionData,

      editSectionModal,
      editSectionId,
      editSectionName,
      editTranslation,
      editSectionNameTranslation,
    } = useSectionsList();

    return {
      // Sidebar
      isAddNewSectionSidebarActive,

      fetchSections,
      storeSection,
      updateSection,
      tableColumns,
      per_page,
      current_page,
      totalSections,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSectionsListTable,
      refetchData,

      // Add Section
      addSectionModal,
      addSectionName,
      addTranslation,
      addSectionNameTranslation,

      sectionData,
      editSectionDetails,

      // Edit Section
      editSectionModal,
      editSectionId,
      editSectionName,
      editTranslation,
      editSectionNameTranslation,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
